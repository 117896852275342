<template>
  <div class="new-content-box" v-if="chatbot">
    <div class="new-box-heading">
      <img src="@/assets/Kodefast.svg" />
      <span>Try Using AI</span>
      <el-tooltip content="Preview" placement="top">
        <i
          class="el-icon-view"
          style="margin-left: auto; font-size: 15px; cursor: pointer"
          @click="OpenPreview"
        ></i>
      </el-tooltip>
      <span class="divider"></span>
      <el-link :underline="false" class="Summarize" @click="generateSummary"
        ><span>Summarize</span></el-link
      >
      <span class="divider"></span>
      <i
        class="el-icon-close"
        style="font-size: 15px; cursor: pointer"
        @click="CloseChatBot"
      ></i>
    </div>
    <div>
      <div
        v-if="loading"
        :class="
          isFromDocument
            ? 'nested-box-docs loading-container'
            : 'nested-box loading-container'
        "
      >
        <img :src="emailTemplate" alt="Loading..." />
        <p v-if="!isFromDocument">
          Just a moment, we are creating an email template for you.
        </p>
        <p v-if="isFromDocument">
          Just a moment, we are creating a document template for you.
        </p>
      </div>
      <div v-else :class="isFromDocument ? 'nested-box-docs' : 'nested-box'">
        <div v-html="currentResponse" v-if="disableBox"></div>
      </div>
    </div>
    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.sentiment"
    >
      <strong>Sentiment:</strong> {{ currentResponseForkeys.data.sentiment }}
    </div>
    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.keyword_extraction"
    >
      <strong>Keyword Extraction:</strong>
      {{ currentResponseForkeys.data.keyword_extraction }}
    </div>
    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.word_count"
    >
      <strong>Word Count:</strong> {{ currentResponseForkeys.data.word_count }}
    </div>
    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.entity_extraction"
    >
      <strong>Entity Extraction:</strong>
      <ul>
        <li
          v-for="(entity, index) in currentResponseForkeys.data
            .entity_extraction"
          :key="index"
        >
          <strong style="font-size: 12px">{{ entity.entity }}:</strong>
          {{ entity.value }}
        </li>
      </ul>
    </div>

    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.paraphrase"
    >
      <strong>Paraphrase:</strong> {{ currentResponseForkeys.data.paraphrase }}
    </div>

    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.phrase_extraction"
    >
      <strong>Phrase Extraction:</strong>
      <ul>
        <li
          v-for="(phrase, index) in currentResponseForkeys.data
            .phrase_extraction"
          :key="index"
        >
          {{ phrase }}
        </li>
      </ul>
    </div>

    <div
      class="nested-box-docs-for-keywords"
      v-if="currentResponseForkeys?.data?.grammar_spell_check"
    >
      <strong>Grammar/Spell Check:</strong>
      {{ currentResponseForkeys.data.grammar_spell_check }}
    </div>
    <div class="select-container">
      <div>
        <div class="mt-1">
          Entity Fields
          <span>
            <el-popover
              class="Description"
              placement="top-start"
              trigger="hover"
              width="443"
              :content="`Please choose the fields you'd like to include in the ${
                isFromDocument ? 'Template.' : 'Email template.'
              }`"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </div>
        <div class="d-flex">
          <el-select
            v-if="
              type == 'email_template' ||
              type == 'document_template' ||
              type == 'Configure_Email'
            "
            v-model="selectedFields"
            multiple
            placeholder="Select"
            collapse-tags
            class="select-box mb-10"
            filterable
            :filter-method="filterEntityFields"
            @change="handleSelectChange"
          >
            <el-option
              v-for="(item, index) in filteredEntityFields"
              :key="'FilterEntityField' + item.key + index"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>

          <el-checkbox
            v-if="isFromDocument && fileList && !fileList.length"
            class="ml-1 mt-1"
            v-model="use_editor_input"
            :disabled="
              ckContent === '' || (ckContent !== '' && this.pdfData.length)
            "
            @change="handleCheckboxChange"
          >
            Use Ck Editor Input
            <el-popover
              class="Description"
              v-if="isFromDocument && fileList && !fileList.length"
              placement="top"
              trigger="hover"
              width="370"
              :content="`You can perform AI operations on the CKEditor content.`"
            >
              <i class="el-icon-info" slot="reference"></i> </el-popover
          ></el-checkbox>
        </div>
      </div>
      <div>
        <div class="mt-1">
          <div v-if="!isFromDocument">Global Fields</div>
        </div>
        <el-select
          v-if="type == 'email_template' || type == 'Configure_Email'"
          v-model="selectedGlobalFields"
          multiple
          placeholder="Select"
          collapse-tags
          class="select-box mb-10"
          filterable
          :filter-method="filterGlobalFields"
        >
          <el-option
            v-for="(item, index) in filteredGlobalFields"
            :key="'FilterGlobalField' + index + '_' + item.key"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="input-with-icon">
      <el-row>
        <el-col :span="20">
          <el-input
            autosize
            placeholder="Enter Desired Prompt Here"
            type="textarea"
            v-model="input1"
            @input="handleInput"
            class="inherit-font"
          ></el-input>
        </el-col>
        <el-col :span="1" style="margin-left: 3px">
          <el-tooltip placement="top" v-if="!this.use_editor_input">
            <div slot="content">
              You can perform AI operations <br />
              on the uploaded attachment's content.
            </div>
            <el-upload action :on-change="handleChange" :show-file-list="false">
              <el-button size="mini" style="height: 32px; width: 30px">
                <i class="el-icon-paperclip" style="margin-left: -5px"></i>
              </el-button>
            </el-upload>
          </el-tooltip>
          <!-- <pdf-viewer-root :url="pdfFile" /> -->
          <img
            :src="iconSrc"
            class="suffix-icon"
            alt="Enter Icon"
            style="margin-left: 10px"
            @click="handleIconClick"
          />
        </el-col>
        <el-col :span="24" class="name-types" v-if="pdfData && pdfData.length">
          <div style="padding: 12px; border: 1px solid #dfe3eb">
            <span><i class="el-icon-document"></i> {{ pdfFileName }} </span>
            <span
              style="float: right; color: #f754a2"
              @click="deleteUploadedDoc"
              ><i class="el-icon-delete" type="danger"></i
            ></span>
          </div>
        </el-col>
        <!-- <el-col
          :span="2"
          v-if="pdfData && pdfData.length"
        >
          <div style="margin-left:2px;color:#f754a2;margin-top:30%">
            
          </div>
        </el-col> -->
      </el-row>
    </div>
    <div v-if="responseGenerated || use_editor_input">
      <div class="icon-container">
        <div style="margin-right: 5px; margin-bottom: 5px">
          <el-tooltip content="Previous" placement="top">
            <i
              class="el-icon-arrow-left"
              @click="handleArrowClick('left')"
              style="cursor: pointer"
            ></i>
          </el-tooltip>

          <span>{{ currentResponseIndex + 1 }}</span>
          <el-tooltip content="Next" placement="top">
            <i
              class="el-icon-arrow-right"
              style="cursor: pointer"
              @click="handleArrowClick('right')"
            ></i>
          </el-tooltip>
        </div>
        <div>
          <el-tooltip content="Copy" placement="top">
            <img src="@/assets/Copy.svg" @click="copyContent(false)" />
          </el-tooltip>
          <el-tooltip
            content="Append"
            placement="top"
            style="font-size: 18px; margin-right: 6px"
          >
            <i class="el-icon-plus" @click="copyContent(true)"></i>
          </el-tooltip>
          <el-tooltip content="Regenerate" placement="top">
            <img
              src="@/assets/Regenerate.svg"
              @click="setPromptDetail('Regenerate', 'regenerate')"
            />
          </el-tooltip>
          <div class="image-container">
            <img src="@/assets/Sliders.svg" alt="Sliders" class="hover-image" />
            <div class="hover-items">
              <div
                class="item"
                @click="setPromptDetail('ChangeFormate', 'short')"
              >
                Short
              </div>
              <div
                class="item"
                @click="setPromptDetail('ChangeFormate', 'Medium')"
              >
                Medium
              </div>
              <div
                class="item"
                @click="
                  setPromptDetail(
                    'ChangeFormate',
                    'long with more than 5 paragraphs'
                  )
                "
              >
                Eloborate
              </div>
            </div>
          </div>
          <div class="image-container">
            <img
              src="@/assets/emoji-smile.svg"
              alt="Sliders"
              class="hover-image"
            />
            <div class="hover-items">
              <div class="item" @click="setPromptDetail('Formate', 'Formal')">
                Formal
              </div>
              <div class="item" @click="setPromptDetail('Formate', 'Informal')">
                Informal
              </div>
              <div class="item" @click="setPromptDetail('Formate', 'Friendly')">
                Friendly
              </div>
              <div
                class="item"
                @click="setPromptDetail('Formate', 'Persuasive')"
              >
                Persuasive
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/img/icons/pencil.svg" class="hover-image" />
            <div class="hover-items">
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'keyword extraction')"
              >
                Keyword Extraction
              </div>
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'sentiment analysis')"
              >
                Sentiment Analysis
              </div>
            </div>
          </div>
          <div class="image-container">
            <img
              src="@/assets/img/icons/more-icon.svg"
              style="transform: rotate(90deg)"
              class="hover-image"
            />
            <div class="hover-items">
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'Word Count')"
              >
                Word Count
              </div>
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'Phrase Extraction')"
              >
                Phrase Extraction
              </div>
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'Grammar Spell Check')"
              >
                Grammar Spell Check
              </div>
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'Paraphrase')"
              >
                Paraphrase
              </div>
              <div
                class="moreItem"
                @click="setPromptDetail('Formate', 'Entity Extraction')"
              >
                Entity Extraction
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-component
      :title="'Preview'"
      :visible="PreviewContent"
      @before-close="PreviewContent = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'70%'"
      :containerMinHeight="'30%'"
      :isShowFooter="false"
    >
      <div class="PreviewOfContent" v-html="currentResponse"></div>
    </dialog-component>
    <dialog-component
      :title="isFromDocument ? 'Document Summary' : 'Email Summary'"
      :visible="EmailSummary"
      @before-close="EmailSummary = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'60%'"
      :containerMinHeight="'20%'"
      :isShowFooter="false"
    >
      <div class="PreviewOfContent">
        <p v-if="!isFromDocument">
          Here is the summary of your Email Template <b>{{ entity.name }}</b>
        </p>
        <p v-if="isFromDocument">
          Here is the summary of your Document Template <b>{{ entity }}</b>
        </p>
        <p>
          This <b v-if="isFromDocument">{{ entity }} </b>
          <b v-if="!isFromDocument">{{ entity.name }} </b> includes the
          following fields:
        </p>
        <ul>
          <li
            v-for="(field, index) in selectedFields"
            :key="'Field_' + index + '_' + field"
          >
            {{ field }}
          </li>
          <li
            v-for="(Globalfield, globalIndex) in selectedGlobalFields"
            :key="'GlobalField_' + globalIndex + '_' + Globalfield"
          >
            {{ Globalfield }}
          </li>
        </ul>
        <br />
        <p>
          for the purpose of <b v-if="isFromDocument">{{ entity }} </b>
          <b v-if="!isFromDocument">{{ entity.name }} </b>
        </p>
        <p>Thank you.</p>
      </div>
    </dialog-component>
  </div>
</template>

<script>
import axios from "axios";
import nodeAxios from "@/config/axios";
import { mapGetters } from "vuex";
import { bus } from "../main";
// import VuePdfApp from "vue-pdf-app";
// import "pdf-viewer-root"
export default {
  data() {
    return {
      currentResponseForkeys: null,
      fileList: [],
      use_editor_input: false,
      pdfFile: null,
      pdfData: "",
      pdfFileName: "",
      isLargeScreen: false,
      input1: "",
      EmailSummary: false,
      showCurrentScreen: true,
      chatbot: true,
      PreviewContent: false,
      nestedText: "",
      updatePromptFlag: false,
      loading: false,
      aIGeneratedFields: [],
      options: [],
      iconSrc: require("@/assets/Enter-Active.svg"),
      emailTemplate: require("@/assets/Email-Template-generate.gif"),
      selectedFields: [],
      selectedGlobalFields: [],
      Regenerate: "",
      ChangeFormate: "",
      Formate: "",
      summaryText: "",
      filteredEntityFields: [],
      filteredGlobalFields: [],
      disableBox: true,
      responseGenerated: false,
      regeneratedResponses: [],
      currentResponseIndex: 0,
      isChanges: false,
      showPdfPreview: false,
      ckContent: "",
      entityName1: this.entityName,
    };
  },
  components: {
    // VuePdfApp,
  },
  created() {
    bus.$on("updatingNameInAIPrompt", (data) => {
      if (this.isFromDocument) {
        this.input1 = `Generate Content for ${data.documentName}`;
        this.filteredEntityFields = data.mentionFields;
      }
    });
    bus.$on("updatingContentInAIPrompt", (data) => {
      if (this.isFromDocument) {
        this.ckContent = data.editorContent;
      }
    });
    //updatingContentInAIPrompt
  },
  mounted() {
    if (this.type == "email_template") {
      this.input1 = `Generate Email Template for ${this.entity.name}`;
    } else if (this.type == "Configure_Email") {
      this.input1 = `Generate Email Template for ${this.entityName1}`;
    } else {
      this.input1 = `Generate Content for ${this.entity}`;
    }
    this.session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
    this.filteredEntityFields = this.entity_fields;
    this.filteredGlobalFields = this.global_fields;
    this.ckContent = this.editorContent;
    this.handleCheckContentInEditor();
  },
  props: [
    "entity_fields",
    "entity",
    "type",
    "global_fields",
    "isFromDocument",
    "isFromBuilder",
    "editorContent",
    "Configure_Email",
    "entityName",
  ],
  methods: {
    deleteUploadedDoc() {
      this.pdfData = "";
      this.pdfFileName = "";
    },
    handleCheckContentInEditor() {
      if (this.ckContent !== "") {
        return false;
      } else {
        return true;
      }
    },
    showPreview() {
      this.showPdfPreview = true;
    },
    closePreview() {
      this.showPdfPreview = false;
    },
    async handleChange(file) {
      try {
        this.loading = true;
        this.loadingText = "Uploading Attachment...";
        this.pdfData = "";
        this.pdfFileName = file && file.name ? file.name : file.raw.name;
        var formData = new FormData();
        formData.append("files", file.raw !== undefined ? file.raw : file);
        formData.append("path", "temporary-files");
        formData.append("include_presigned_urls", true);
        let filesData = await nodeAxios.post(`/presignMultiUpload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        let url;
        if (Object.keys(filesData.data.urlsObject).length) {
          url =
            filesData.data.urlsObject[Object.keys(filesData.data.urlsObject)[0]]
              ?.downloadURL;
          this.$message.success("Attachment Uploaded Successfully...");
        }
        this.loading = false;
        this.pdfData = url;
      } catch (err) {
        console.log(err);
      }
    },
    handleSelectChange(selectedItems) {
      if (this.isFromDocument) {
        this.$emit("change", selectedItems);
      }
    },
    OpenEmailSummary() {
      this.EmailSummary = true;
    },
    updateEntityFields(newFields) {
      if (this.isFromDocument) {
        this.filteredEntityFields = newFields;
      }
    },
    CloseChatBot() {
      this.$emit("close-chatbot", this.chatbot);
    },
    handleCheckboxChange() {
      if (this.use_editor_input) {
        this.iconSrc = require("@/assets/Enter-Active.svg");
      } else {
        this.iconSrc = require("@/assets/Enter-Inactive.svg");
      }
    },
    handleInput() {
      if (this.input1.trim().length > 0 && !this.loading) {
        this.iconSrc = require("@/assets/Enter-Active.svg");
      } else {
        this.iconSrc = require("@/assets/Enter-Inactive.svg");
      }
    },
    filterEntityFields(query) {
      if (query !== "") {
        this.filteredEntityFields = this.entity_fields.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        this.filteredEntityFields = this.entity_fields;
      }
    },
    filterGlobalFields(query) {
      if (query !== "") {
        this.filteredGlobalFields = this.global_fields.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        this.filteredGlobalFields = this.global_fields;
      }
    },
    copyContent(isAppend = false) {
      this.$emit("copy-content", {
        content: this.currentResponse,
        isAppend: isAppend,
        AIFields: this.aIGeneratedFields,
      });
      this.use_editor_input = false;
    },
    getGlobalFileds() {
      this.selectedGlobalFields = [];
      this.global_fields.map((field) => {
        this.selectedGlobalFields.push(field.label);
      });
    },
    getEntityFields() {
      this.selectedFields = [];
      this.entity_fields.map((field) => {
        this.selectedFields.push(field.label);
      });
    },
    handleIconClick() {
      if (!this.loading && this.input1.length > 0) {
        this.isChanges = true;
        this.generateResponse();
      }
    },
    async generateResponse() {
      const Input = this.input1.trim();
      if (Input.length > 0 || this.updatePromptFlag) {
        try {
          this.loading = true;
          this.iconSrc = require("@/assets/Enter-Inactive.svg");
          this.responseGenerated = false;
          this.disableBox = false;
          let prompt = Input;
          if (this.Regenerate === "regenerate") {
            prompt = `regenerate by adding some more content`;
          } else if (this.isFromDocument && this.updatePromptFlag) {
            prompt = `give ${this.Formate}, data,fields.`;
          } else if (
            this.updatePromptFlag &&
            this.selectedFields.length > 0 &&
            this.selectedGlobalFields.length > 0
          ) {
            prompt = `${this.ChangeFormate} ${
              this.Formate
            } with all these fields ([${this.selectedFields.join(
              ", "
            )}] [${this.selectedGlobalFields.join(
              ", "
            )}]) in the HTML body and also generate the email HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
          } else if (this.updatePromptFlag) {
            prompt = `${this.ChangeFormate} ${this.Formate}`;
          } else {
            if (
              this.selectedFields.length === 0 &&
              this.selectedGlobalFields.length === 0 &&
              !this.isFromDocument
            ) {
              prompt = `${Input} in the HTML body and also generate the email HTML template with padding and clear the box-shadow and background-color from all the inline styles.`;
            } else if (
              this.selectedFields.length > 0 &&
              this.selectedGlobalFields.length === 0 &&
              !this.isFromDocument
            ) {
              prompt = `${Input} with these fields (${this.selectedFields.join(
                ", "
              )}) in the HTML body and also generate the email HTML template with padding and clear the box-shadow and background-color from all the inline styles.`;
            } else if (
              this.selectedFields.length === 0 &&
              this.selectedGlobalFields.length > 0 &&
              !this.isFromDocument
            ) {
              prompt = `${Input} with these fields (${this.selectedGlobalFields.join(
                ", "
              )}) in the HTML body and also generate the email HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
            } else if (
              this.selectedFields.length > 0 &&
              this.selectedGlobalFields.length > 0 &&
              !this.isFromDocument
            ) {
              prompt += ` with all these fields (${this.selectedFields.join(
                ", "
              )} ${this.selectedGlobalFields.join(
                ", "
              )}) in the HTML body and also generate the email HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
            } else {
              if (
                this.isFromDocument &&
                this.selectedFields &&
                this.selectedFields.length === 0
              ) {
                prompt +=
                  ". In the HTML body and also generate the HTML template with padding and clear the box-shadow and background-color from all the inline styles.";
              } else if (
                this.isFromDocument &&
                this.selectedFields &&
                this.selectedFields.length > 0
              ) {
                prompt += ` with all these fields (${this.selectedFields.join(
                  ", "
                )} ${this.selectedGlobalFields.join(
                  ", "
                )}) in the HTML body and also generate the HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
              }
            }
          }
          let response;
          if (!this.pdfData) {
            response = await axios.post("https://ai.esigns.io/emailtemplate/", {
              Enter_your_prompt: prompt,
              session_id: this.session_id,
              flag: this.isChanges,
              include_fields: this.isFromDocument || false,
              ai_content: this.use_editor_input ? this.ckContent || "" : "",
            });
          } else {
            response = await axios.post(
              "https://ai.esigns.io/document_template/",
              {
                session_id: this.session_id,
                flag: this.isChanges,
                include_fields: this.isFromDocument || false,
                url: this.pdfData ? this.pdfData : "",
              }
            );
          }
          let data =
            response && response.data && response.data.data
              ? response.data.data
              : response.data;
          this.aIGeneratedFields =
            response && response.data && response.data.fields
              ? response.data.fields
              : [];
          this.currentResponseForkeys = response;
          let cleanData = data.replace(/html/g, "");
          if (this.selectedGlobalFields.length > 0) {
            this.selectedGlobalFields.map((fd) => {
              cleanData = cleanData.replace(`[[${fd}]]`, `((${fd}))`);
            });
          }
          console.log(response);
          let array;
          if (cleanData.includes("```")) {
            array = cleanData.split("```");
            if (array?.length) {
              this.nestedText = array[1];
            } else {
              this.nestedText = "";
            }
          } else {
            array = cleanData.split("**Variables:**");
            if (array?.length) {
              this.nestedText = array[0];
            } else {
              this.nestedText = "";
            }
          }

          this.regeneratedResponses.push(this.nestedText);
          this.currentResponseIndex = this.regeneratedResponses.length - 1;
          this.loading = false;
          this.disableBox = true;
          this.responseGenerated = true;
          this.handleInput();
          this.updatePromptFlag = false;
          this.Regenerate = "";
        } catch (error) {
          console.error("Error generating response:", error);
          this.loading = false;
          this.$message.error(
            "Error in generating response. Please try with a different prompt or select fields and generate the content."
          );
          this.handleInput();
        }
      }
    },
    async setPromptDetail(type, value) {
      if (
        this.selectedFields.length === 0 &&
        this.selectedGlobalFields.length === 0 &&
        !this.isFromDocument
      ) {
        this.$message.error({
          showClose: true,
          message: "Please select some fields",
          center: true,
        });
        return;
      }
      this[type] = value;
      this.updatePromptFlag = true;
      this.isChanges = false;
      await this.generateResponse();
    },
    handleArrowClick(direction) {
      if (direction === "left") {
        this.currentResponseIndex = Math.max(0, this.currentResponseIndex - 1);
      } else if (direction === "right") {
        this.currentResponseIndex = Math.min(
          this.regeneratedResponses.length - 1,
          this.currentResponseIndex + 1
        );
      }
    },
    OpenPreview() {
      if (!this.nestedText) {
        this.$message.error({
          showClose: true,
          message: "Generate Content to View",
          center: true,
        });
      } else {
        this.PreviewContent = true;
      }
    },
    generateSummary() {
      if (this.selectedFields.length === 0 && !this.isFromDocument) {
        this.$message.error({
          showClose: true,
          message: "Please select some fields",
          center: true,
        });
      } else {
        this.OpenEmailSummary();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    currentResponse() {
      return this.regeneratedResponses[this.currentResponseIndex] || "";
    },
  },
  watch: {
    "entity._id": {
      handler() {
        // (this.filteredEntityFields = []), (this.selectedFields = []);
        // this.selectedGlobalFields = [];
        // this.nestedText = "";
        // this.ChangeFormate = "";
        // this.Formate = "";
        this.input1 = `Generate Email Template for ${
          this.entity.name ? this.entity.name : this.entityName
        }`;
        // this.regeneratedResponses = [];
        // this.currentResponseIndex = 0;
      },

      immediate: true,
      deep: true,
    },
    entity_fields: {
      handler(newVal) {
        this.updateEntityFields(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.inherit-font ::v-deep .el-textarea__inner {
  font-family: "Inter";
  font-size: 13px;
}
.Description {
  color: #c0c4cc;
}
.select-container {
  display: flex;
  gap: 28px;
}
.divider {
  border-left: 1px solid #dcdfe6;
  height: 20px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}
.PreviewOfContent {
  scrollbar-width: thin;
  height: 40vh;
  overflow-y: auto;
}
.Summarize {
  color: #409eff;
}
.image-container {
  position: relative;
  display: inline-block;
}

.hover-image {
  display: block;
}
.el-select ::v-deep .el-tag {
  max-width: 65% !important;
}
.hover-items {
  display: none;
  position: absolute;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.hover-items::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 45%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.image-container:hover .hover-items {
  display: block;
}

.item {
  font-size: 15px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.moreItem {
  font-size: 15px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 160px;
  cursor: pointer;
}
.item:hover {
  background-color: #f0f0f0;
}

.input-with-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.suffix-icon {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.input-with-icon .el-input__inner {
  padding-right: 40px;
}
.check .el-input__inner + .el-input__icon {
  font-size: 100px;
}
.select-box {
  width: 100%;
  max-width: 223px;
  margin-top: 5px;
}

.new-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.new-content-box {
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  padding: 20px;
  width: 90%;
  overflow-y: auto;
  scrollbar-width: thin;
  max-width: 600px;
  height: 655px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    margin-left: 200px;
    width: 60%;
    position: relative;
    bottom: 50px;
  }
}

.new-box-heading {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: thin;
}
.nested-box-docs {
  color: black;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: #f8fafc;
  height: 360px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.nested-box-docs-for-keywords {
  color: black;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: #f8fafc;
  height: 100px;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.nested-box {
  color: black;
  overflow-y: auto;
  scrollbar-width: thin;
  height: 40vh;
  background-color: #f8fafc;
  height: 260px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.icon-container {
  display: flex;
  margin-top: 14px;
  margin-left: 2px;
}
.icon-container img {
  margin-right: 10px;
  height: 16px;
}
@media (max-width: 1366px) and (min-width: 768px) {
  .nested-box {
    height: 185px;
  }
}
.name-types {
  margin-top: 2%;
  .filename {
    height: 56px;
    margin-top: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;
    .el-upload-list__item-name {
      margin-top: 1em;
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
    }
  }
}
.pdf-viewer-container {
  width: 100%;
  height: 30vh;
  border: 1px solid #dfe3eb;
  padding: 3px;
}
</style>
